<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showCropPeriodDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="cropPeriods" class="mt-2">
            <el-table-column prop="name" label="名称" sortable />
            <el-table-column prop="days" label="出苗水后天数" sortable width="140" />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showCropPeriodDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deleteCropPeriod(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="cropPeriodDialogVisible" title="作物生育期" :close-on-click-modal="false" append-to-body
            width="400px">
            <el-form>
                <el-form-item label="名称" class="form-item-required">
                    <el-input v-model="cropPeriod.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="出苗水后天数" class="form-item-required">
                    <el-input-number v-model="cropPeriod.days" controls-position="right" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveCropPeriod">确定</el-button>
                <el-button @click="cropPeriodDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            cropId: { type: String },
        },
        data() {
            return {
                cropPeriods: [],
                cropPeriod: {},
                cropPeriodDialogVisible: false,
            };
        },
        methods: {
            async loadCropPeriods() {
                let response = await this.$axios.get('/api/Crop/GetCropPeriods', { params: { cropId: this.cropId } });
                this.cropPeriods = response.data;
            },
            showCropPeriodDialog(cropPeriod) {
                if (cropPeriod) {
                    this.cropPeriod = JSON.parse(JSON.stringify(cropPeriod));
                }
                else {
                    this.cropPeriod = { days: 0 };
                }
                this.cropPeriodDialogVisible = true;
            },
            async saveCropPeriod() {
                if (this.cropPeriod.id == undefined) {
                    await this.$axios.post('/api/Crop/AddCropPeriod', {
                        cropId: this.cropId,
                        name: this.cropPeriod.name,
                        days: this.cropPeriod.days,
                    });
                    this.cropPeriodDialogVisible = false;
                    this.loadCropPeriods();
                }
                else {
                    await this.$axios.post('/api/Crop/UpdateCropPeriod', {
                        id: this.cropPeriod.id,
                        name: this.cropPeriod.name,
                        days: this.cropPeriod.days,
                    });
                    this.cropPeriodDialogVisible = false;
                    this.loadCropPeriods();
                }
            },
            async deleteCropPeriod(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Crop/DeleteCropPeriod', { id: id });
                this.loadCropPeriods();
            },
        },
        created() {
            this.loadCropPeriods();
        },
    };
</script>