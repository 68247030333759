<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showCropWatermanureDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="cropWatermanures" class="mt-2">
            <el-table-column prop="name" label="名称" sortable />
            <el-table-column prop="sequence" label="序号" sortable width="120" />
            <el-table-column prop="daysBetween" label="间隔天数" sortable width="120" />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showCropWatermanureDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deleteCropWatermanure(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="cropWatermanureDialogVisible" title="作物水肥" :close-on-click-modal="false"
            append-to-body width="480px">
            <el-form>
                <el-form-item label="名称" class="form-item-required">
                    <el-input v-model="cropWatermanure.name" placeholder="名称" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="序号" class="form-item-required">
                            <el-input-number v-model="cropWatermanure.sequence" controls-position="right" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="间隔天数" class="form-item-required">
                            <el-input-number v-model="cropWatermanure.daysBetween" controls-position="right" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="备注">
                    <el-input v-model="cropWatermanure.remark" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveCropWatermanure">确定</el-button>
                <el-button @click="cropWatermanureDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            cropId: { type: String },
        },
        data() {
            return {
                cropWatermanures: [],
                cropWatermanure: {},
                cropWatermanureDialogVisible: false,
            };
        },
        methods: {
            async loadCropWatermanures() {
                let response = await this.$axios.get('/api/Crop/GetCropWatermanures', { params: { cropId: this.cropId } });
                this.cropWatermanures = response.data;
            },
            showCropWatermanureDialog(cropWatermanure) {
                if (cropWatermanure) {
                    this.cropWatermanure = JSON.parse(JSON.stringify(cropWatermanure));
                }
                else {
                    this.cropWatermanure = { sequence: this.getNextNumber(this.cropWatermanures.map(p => p.sequence)), daysBetween: 0 };
                }
                this.cropWatermanureDialogVisible = true;
            },
            getNextNumber(numbers) {
                if (numbers.length == 0) {
                    return 1;
                }

                return Math.max(...numbers) + 1;
            },
            async saveCropWatermanure() {
                if (this.cropWatermanure.id == undefined) {
                    await this.$axios.post('/api/Crop/AddCropWatermanure', {
                        cropId: this.cropId,
                        name: this.cropWatermanure.name,
                        sequence: this.cropWatermanure.sequence,
                        daysBetween: this.cropWatermanure.daysBetween,
                        remark: this.cropWatermanure.remark,
                    });
                    this.cropWatermanureDialogVisible = false;
                    this.loadCropWatermanures();
                }
                else {
                    await this.$axios.post('/api/Crop/UpdateCropWatermanure', {
                        id: this.cropWatermanure.id,
                        name: this.cropWatermanure.name,
                        sequence: this.cropWatermanure.sequence,
                        daysBetween: this.cropWatermanure.daysBetween,
                        remark: this.cropWatermanure.remark,
                    });
                    this.cropWatermanureDialogVisible = false;
                    this.loadCropWatermanures();
                }
            },
            async deleteCropWatermanure(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Crop/DeleteCropWatermanure', { id: id });
                this.loadCropWatermanures();
            },
        },
        created() {
            this.loadCropWatermanures();
        },
    };
</script>