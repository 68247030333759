<template>
    <div class="p-3">
        <el-card shadow="never">
            <el-table :data="crops">
                <el-table-column width="90">
                    <template #default="scope">
                        <el-image :src="scope.row.iconUrl" fit="fill" style="width: 64px; height: 64px;" />
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column label="操作" fixed="right" width="170">
                    <template #default="scope">
                        <el-button type="text" @click="showCropPeriodsDialog(scope.row)">生育期设置</el-button>
                        <el-button type="text" @click="showCropWatermanuresDialog(scope.row)">水肥设置</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog :visible.sync="cropPeriodsDialogVisible" :title="crop.name+' 生育期设置'" :close-on-click-modal="false"
            width="640px">
            <crop-periods v-if="cropPeriodsDialogVisible" :crop-id="crop.id" />
        </el-dialog>

        <el-dialog :visible.sync="cropWatermanuresDialogVisible" :title="crop.name+' 水肥设置'"
            :close-on-click-modal="false" width="640px">
            <crop-watermanures v-if="cropWatermanuresDialogVisible" :crop-id="crop.id" />
        </el-dialog>
    </div>
</template>

<script>
    import CropPeriods from './components/CropPeriods.vue';
    import CropWatermanures from './components/CropWatermanures.vue';

    export default {
        data() {
            return {
                crops: [],
                crop: {},
                cropPeriodsDialogVisible: false,
                cropWatermanuresDialogVisible: false,
            };
        },
        components: { CropPeriods, CropWatermanures },
        methods: {
            async loadCrops() {
                let response = await this.$axios.get('/api/Crop/GetCrops');
                this.crops = response.data;
            },
            showCropPeriodsDialog(crop) {
                this.crop = JSON.parse(JSON.stringify(crop));
                this.cropPeriodsDialogVisible = true;
            },
            showCropWatermanuresDialog(crop) {
                this.crop = JSON.parse(JSON.stringify(crop));
                this.cropWatermanuresDialogVisible = true;
            },
        },
        created() {
            this.loadCrops();
        },
    };
</script>